import Socials from './Socials';
import footerLogo from '../tmb_footer_logo.svg';


function Footer() {
    return (
        <footer className="-z-0 flex flex-col w-full static bottom-0 text-antique-beige">
            <div className="flex flex-row">
                <img alt="" className="p-4 max-w-[200px] h-auto" src={footerLogo}></img>
            </div>
            <div id="footer-info" className="flex flex-col lg:flex-row border justify-between">
                < Socials/>
                <div className="flex flex-col  sm:px-12 p-4">
                    <h1>Address:</h1>
                    <div className="pb-4">
                        218 E State St, Rockford, IL 61104
                    </div>
                    <h1>Contact:</h1>
                    <div>
                        (815) 986-7396
                    </div>
                    <div>
                        info@themusicboxrockford.com
                    </div>
                    <div className="pb-4">
                        booking@themusicboxrockford.com
                    </div>
                    <div>
                        Hours:
                    <div>
                        Monday 4PM - 12AM
                    </div>
                    <div>
                        Tuesday 4PM - 12AM
                    </div>
                    <div>
                        Wednesday 4PM - 12AM
                    </div>
                    <div>
                        Thursday 4PM - 12AM
                    </div>
                    <div>
                        Friday 4PM - 1AM
                    </div>
                    <div>
                        Saturday 2PM - 1AM
                    </div>
                    <div>
                        Sunday CLOSED 
                    </div>
                    </div>
                </div>
                <div id="map-div" className="flex h-auto p-4">
                    <div className="border-solid border-4 border-vintage-brass">
                        <iframe
                            title="map-component"
                            className='w-[325px] h-[275px] md:w-[525px] md:h-[400px]'
                            style={{border: '2px', borderColor: '#B39055', aspectRatio: '16/9'}}
                            loading="lazy" 
                            allowfullscreen
                            referrerpolicy="no-referrer-when-downgrade"
                            src={process.env.REACT_APP_GM_API_URL}>
                        </iframe>
                    </div>
                </div>
            </div>
            <div id="footer-base" className="text-center py-2">
                © The Music Box of Rockford 2024 
            </div>
        </footer>
    )
};

export default Footer;